@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  height: 100vh;
}

#root {
  height: 100%;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}